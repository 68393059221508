<template>
  <div>
    <b-row>
      <b-col lg="6" md="12">
        <b-card class="card_statisc">
          <b-row>
            <b-col
              md="12"
              class="mb-2 mt-2"
            >
              <statistic-card-vertical
                icon="CpuIcon"
                :statistic="totalCabinet"
                statistic-title="Total de gabinetes instalados"
                color="info"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col lg="6" md="12">
        <b-card class="card_statisc">
          <b-row>
            <b-col
              md="12"
              class="mb-2 mt-2"
            >
              <statistic-card-vertical
                icon="WifiIcon"
                :statistic="totalTransmitting"
                statistic-title="Total de medidores"
                color="success"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col md="12" >
        <b-card>
          <div class="text-center"><b>Gabinetes por día</b></div>
          <vue-apex-charts
            v-if="cabinetsByDayChart.ready"
            type="bar"
            height="400"
            :options="cabinetsByDayChart.chartOptions"
            :series="cabinetsByDayChart.series"
          />
        </b-card>
      </b-col>
      <b-col md="12" >
        <b-card>
          <div class="text-center"><b>Gabinetes por semana</b></div>
          <vue-apex-charts
            v-if="cabinetsByWeekChart.ready"
            type="bar"
            height="400"
            :options="cabinetsByWeekChart.chartOptions"
            :series="cabinetsByWeekChart.series"
          />
        </b-card>
      </b-col>
      <b-col md="12" >
        <b-card>
          <div class="text-center">
            <b>Gabinetes por agencias</b>
            <b-col md="12" class="text-center d-flex justify-content-end">
              <b-button
                v-if="urlDownload.length > 0 && responseData.length > 0"
                :href="urlDownload"
                download="Gabinetes_conteo.csv"
                variant="outline-primarySpore">
                Descargar CSV
              </b-button>
            </b-col>
          </div>
          <vue-apex-charts
            v-if="agenciesChart.ready"
            type="bar"
            height="400"
            :options="agenciesChart.chartOptions"
            :series="agenciesChart.series"
          />
        </b-card>
      </b-col>
      <b-col md="12" >
        <b-card>
          <div class="text-center"><b>Gabinetes por agencias por día</b></div>
          <vue-apex-charts
            v-if="agenciasByDayChart.ready"
            type="bar"
            height="400"
            :options="agenciasByDayChart.chartOptions"
            :series="agenciasByDayChart.series"
          />
        </b-card>
      </b-col>
      <b-col md="12" >
        <b-card>
          <div class="text-center"><b>Gabinetes por agencias por semana</b></div>
          <vue-apex-charts
            v-if="agenciasByWeekChart.ready"
            type="bar"
            height="400"
            :options="agenciasByWeekChart.chartOptions"
            :series="agenciasByWeekChart.series"
          />
        </b-card>
      </b-col>
      <b-col md="12" >
        <b-card>
          <div class="text-center"><b>Gabinetes por cuadrilla</b></div>
          <vue-apex-charts
            v-if="teamsChart.ready"
            type="bar"
            height="400"
            :options="teamsChart.chartOptions"
            :series="teamsChart.series"
          />
        </b-card>
      </b-col>
      <b-col md="12" >
        <b-card>
          <div class="text-center"><b>Gabinetes por cuadrillas por día</b></div>
          <vue-apex-charts
            v-if="teamsByDayChart.ready"
            type="bar"
            height="400"
            :options="teamsByDayChart.chartOptions"
            :series="teamsByDayChart.series"
          />
        </b-card>
      </b-col>
      <b-col md="12" >
        <b-card>
          <div class="text-center"><b>Gabinetes por cuadrillas por semana</b></div>
          <vue-apex-charts
            v-if="teamsByWeekChart.ready"
            type="bar"
            height="400"
            :options="teamsByWeekChart.chartOptions"
            :series="teamsByWeekChart.series"
          />
        </b-card>
      </b-col>
      <b-col md="12">
        <b-card>
          <b-card-header>
            <b-card-title>Medidores repetidos</b-card-title>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col md="12" class="text-center" v-if="responseMetering.length === 0">
                <h5>No hay medidores en el Gabinete</h5>
              </b-col>
            </b-row>
            <b-table
              v-if="responseMetering.length > 0"
              class="position-relative"
              hover
              responsive
              primary-key="id"
              show-empty
              empty-text="No matching records found"
              :items="responseMetering"
              :fields="tableColumnsMetering"
            >
              <template #cell(Number)="data">
                <div class="text-nowrap selectable" v-if="data.item.number">
                  <b-avatar
                    v-if="data.item.number"
                    size="32"
                    class="avatar-border-2 box-shadow-1"
                    variant="transparent"
                  >
                    <b-img :src="require(`@/assets/images/spore-icons/hash.svg`)" height="20"/>
                  </b-avatar>
                  <span class="align-text-top text-capitalize ml-1">{{ data.item.number.replace(/^(0)+/, '') }}</span>
                </div>
              </template>
              <template #cell(total)="data">
                <div class="text-nowrap selectable" v-if="data.item.total">
                  <b-avatar
                    v-if="data.item.total"
                    size="32"
                    class="avatar-border-2 box-shadow-1"
                    variant="transparent"
                  >
                    <b-img :src="require(`@/assets/images/spore-icons/alias.svg`)" height="20"/>
                  </b-avatar>
                  <span class="align-text-top text-capitalize ml-1">{{ data.item.total }}</span>
                </div>
              </template>
            </b-table>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BCard, BCardBody, BCardTitle, BCardHeader, BRow, BCol, BTable, BButton, BAvatar, BImg
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { empty, timeZoneDate } from '@/libs/tools/helpers'
import request from '@/libs/request/index'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'

export default {
  components: {
    BCard,
    VueApexCharts,
    BRow,
    BCol,
    StatisticCardVertical,
    BTable,
    BCardBody,
    BCardTitle,
    BButton,
    BCardHeader,
    BAvatar,
    BImg
  },
  data () {
    return {
      urlDownload: '',
      responseData: [],
      totalCabinet: 0,
      totalTransmitting: 0,
      responseMetering: [],
      tableColumnsMetering: [
        { key: 'Number', label: 'Número Medidor', sortable: true },
        { key: 'total', label: 'Número Repetido', sortable: true }
      ],
      teamsChart: {
        ready: false,
        series: [
          {
            name: 'Gabinetes',
            data: []
          }
        ],
        chartOptions: {
          chart: {
            stacked: true,
            toolbar: {
              show: false
            }
          },
          plotOptions: {
            bar: {
              columnWidth: '35%',
              colors: {
                backgroundBarRadius: 5
              }
            }
          },
          dataLabels: {
            enabled: false
          },
          legend: {
            show: true,
            position: 'top',
            fontSize: '14px',
            fontFamily: 'Montserrat',
            horizontalAlign: 'left'
          },
          stroke: {
            show: true,
            colors: ['transparent']
          },
          grid: {
            xaxis: {
              lines: {
                show: true
              }
            }
          },
          xaxis: {
            categories: [],
            title: {
              text: 'Cuadrillas'
            }
          },
          yaxis: {
            title: {
              text: 'Gabinetes'
            },
            labels: {
              formatter: function (val) {
                return val.toFixed(0)
              }
            }
          },
          fill: {
            opacity: 1
          }
        }
      },
      agenciesChart: {
        ready: false,
        series: [
          {
            name: 'Gabinetes',
            data: []
          }
        ],
        chartOptions: {
          chart: {
            stacked: true,
            toolbar: {
              show: false
            }
          },
          plotOptions: {
            bar: {
              columnWidth: '35%',
              colors: {
                backgroundBarRadius: 5
              }
            }
          },
          dataLabels: {
            enabled: false
          },
          legend: {
            show: true,
            position: 'top',
            fontSize: '14px',
            fontFamily: 'Montserrat',
            horizontalAlign: 'left'
          },
          stroke: {
            show: true,
            colors: ['transparent']
          },
          grid: {
            xaxis: {
              lines: {
                show: true
              }
            }
          },
          xaxis: {
            categories: [],
            title: {
              text: 'Agencias'
            }
          },
          yaxis: {
            title: {
              text: 'Gabinetes'
            },
            labels: {
              formatter: function (val) {
                return val.toFixed(0)
              }
            }
          },
          fill: {
            opacity: 1
          }
        }
      },
      cabinetsByWeekChart: {
        ready: false,
        series: [
          {
            name: 'Gabinetes',
            data: []
          }
        ],
        chartOptions: {
          chart: {
            stacked: true,
            toolbar: {
              show: false
            }
          },
          plotOptions: {
            bar: {
              columnWidth: '35%',
              colors: {
                backgroundBarRadius: 5
              }
            }
          },
          dataLabels: {
            enabled: false
          },
          legend: {
            show: true,
            position: 'top',
            fontSize: '14px',
            fontFamily: 'Montserrat',
            horizontalAlign: 'left'
          },
          stroke: {
            show: true,
            colors: ['transparent']
          },
          grid: {
            xaxis: {
              lines: {
                show: true
              }
            }
          },
          xaxis: {
            categories: [],
            title: {
              text: 'Semanas'
            }
          },
          yaxis: {
            title: {
              text: 'Gabinetes'
            },
            labels: {
              formatter: function (val) {
                return val.toFixed(0)
              }
            }
          },
          fill: {
            opacity: 1
          }
        }
      },
      cabinetsByDayChart: {
        ready: false,
        series: [
          {
            name: 'Gabinetes',
            data: []
          }
        ],
        chartOptions: {
          chart: {
            stacked: true,
            toolbar: {
              show: false
            }
          },
          plotOptions: {
            bar: {
              columnWidth: '35%',
              colors: {
                backgroundBarRadius: 5
              }
            }
          },
          dataLabels: {
            enabled: false
          },
          legend: {
            show: true,
            position: 'top',
            fontSize: '14px',
            fontFamily: 'Montserrat',
            horizontalAlign: 'left'
          },
          stroke: {
            show: true,
            colors: ['transparent']
          },
          grid: {
            xaxis: {
              lines: {
                show: true
              }
            }
          },
          xaxis: {
            categories: [],
            title: {
              text: 'Días'
            }
          },
          yaxis: {
            title: {
              text: 'Gabinetes'
            },
            labels: {
              formatter: function (val) {
                return val.toFixed(0)
              }
            }
          },
          fill: {
            opacity: 1
          }
        }
      },
      agenciasByDayChart: {
        ready: false,
        series: [],
        chartOptions: {
          chart: {
            height: 350,
            type: 'line',
            stacked: false
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            width: [1, 1, 4]
          },
          xaxis: {
            categories: [],
            title: {
              text: 'Días'
            }
          },
          yaxis: {
            title: {
              text: 'Gabinetes'
            },
            labels: {
              formatter: function (val) {
                return val.toFixed(0)
              }
            }
          },
          tooltip: {
            fixed: {
              enabled: true,
              position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
              offsetY: 30,
              offsetX: 60
            }
          },
          legend: {
            horizontalAlign: 'left',
            offsetX: 40
          }
        }
      },
      teamsByDayChart: {
        ready: false,
        series: [],
        chartOptions: {
          chart: {
            height: 350,
            type: 'line',
            stacked: false
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            width: [1, 1, 4]
          },
          xaxis: {
            categories: [],
            title: {
              text: 'Días'
            }
          },
          yaxis: {
            title: {
              text: 'Gabinetes'
            },
            labels: {
              formatter: function (val) {
                return val.toFixed(0)
              }
            }
          },
          tooltip: {
            fixed: {
              enabled: true,
              position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
              offsetY: 30,
              offsetX: 60
            }
          },
          legend: {
            horizontalAlign: 'left',
            offsetX: 40
          }
        }
      },
      agenciasByWeekChart: {
        ready: false,
        series: [],
        chartOptions: {
          chart: {
            height: 350,
            type: 'line',
            stacked: false
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            width: [1, 1, 4]
          },
          xaxis: {
            categories: [],
            title: {
              text: 'Días'
            }
          },
          yaxis: {
            title: {
              text: 'Gabinetes'
            },
            labels: {
              formatter: function (val) {
                return val.toFixed(0)
              }
            }
          },
          tooltip: {
            fixed: {
              enabled: true,
              position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
              offsetY: 30,
              offsetX: 60
            }
          },
          legend: {
            horizontalAlign: 'left',
            offsetX: 40
          }
        }
      },
      teamsByWeekChart: {
        ready: false,
        series: [],
        chartOptions: {
          chart: {
            height: 350,
            type: 'line',
            stacked: false
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            width: [1, 1, 4]
          },
          xaxis: {
            categories: [],
            title: {
              text: 'Días'
            }
          },
          yaxis: {
            title: {
              text: 'Gabinetes'
            },
            labels: {
              formatter: function (val) {
                return val.toFixed(0)
              }
            }
          },
          tooltip: {
            fixed: {
              enabled: true,
              position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
              offsetY: 30,
              offsetX: 60
            }
          },
          legend: {
            horizontalAlign: 'left',
            offsetX: 40
          }
        }
      },
      donutsChart: [],
      rangePicker: []
    }
  },
  methods: {
    async getDashboard () {
      const params = {
        url: 'report/deployment',
        method: 'GET'
      }
      await request(params).then(response => {
        if (!empty(response.data.general)) {
          this.totalCabinet = response.data.general.total[0].total
          this.cabinetsByDayChart.series[0].data = []
          this.cabinetsByDayChart.chartOptions.xaxis.categories = []
          response.data.general.byDay.forEach((element, index) => {
            this.cabinetsByDayChart.series[0].data.push({
              x: timeZoneDate(element.installAt + ' 23:00:00'),
              y: parseInt(element.total),
              fillColor: '#00c3c3'
            })
          })
          this.cabinetsByDayChart.ready = true

          this.cabinetsByWeekChart.series[0].data = []
          this.cabinetsByWeekChart.chartOptions.xaxis.categories = []
          response.data.general.byWeek.forEach((element, index) => {
            this.cabinetsByWeekChart.series[0].data.push({
              x: element.installAt.toString(),
              y: parseInt(element.total),
              fillColor: '#00c3c3'
            })
          })
          this.cabinetsByWeekChart.ready = true
        }

        if (!empty(response.data.agencies)) {
          this.agenciesChart.series[0].data = []
          this.responseData = []
          this.agenciesChart.chartOptions.xaxis.categories = []
          this.responseData = response.data.agencies.agenciesTotal
          response.data.agencies.agenciesTotal.forEach((element, index) => {
            this.agenciesChart.series[0].data.push({
              x: element.name,
              y: parseInt(element.total),
              fillColor: '#00c3c3'
            })
          })
          this.agenciesChart.ready = true

          this.agenciasByDayChart.series = []
          this.agenciasByDayChart.chartOptions.xaxis.categories = []
          response.data.agencies.agenciesByDay.data.forEach((element, index) => {
            this.agenciasByDayChart.series.push({
              name: element.name,
              data: element.data
            })
          })
          response.data.agencies.agenciesByDay.days.forEach((element, index) => {
            response.data.agencies.agenciesByDay.days[index] = timeZoneDate(element + ' 23:00:00')
          })
          this.agenciasByDayChart.chartOptions.xaxis.categories = response.data.agencies.agenciesByDay.days
          this.agenciasByDayChart.ready = true

          this.agenciasByWeekChart.series = []
          this.agenciasByWeekChart.chartOptions.xaxis.categories = []
          response.data.agencies.arrayGraphagenciesByWeek.data.forEach((element, index) => {
            this.agenciasByWeekChart.series.push({
              name: element.name,
              data: element.data
            })
          })
          response.data.agencies.arrayGraphagenciesByWeek.days.forEach((element, index) => {
            response.data.agencies.arrayGraphagenciesByWeek.days[index] = element.toString()
          })
          this.agenciasByWeekChart.chartOptions.xaxis.categories = response.data.agencies.arrayGraphagenciesByWeek.days
          this.agenciasByWeekChart.ready = true
        }

        if (!empty(response.data.teams)) {
          this.teamsChart.series[0].data = []
          this.teamsChart.chartOptions.xaxis.categories = []
          response.data.teams.teamsTotal.forEach((element, index) => {
            this.teamsChart.series[0].data.push({
              x: !empty(element.name) ? element.name : 'S/N',
              y: parseInt(element.total),
              fillColor: '#00c3c3'
            })
          })
          this.teamsChart.ready = true

          this.teamsByDayChart.series = []
          this.teamsByDayChart.chartOptions.xaxis.categories = []
          response.data.teams.teamsByDay.data.forEach((element, index) => {
            this.teamsByDayChart.series.push({
              name: !empty(element.name) ? element.name : 'S/N',
              data: element.data
            })
          })
          response.data.teams.teamsByDay.days.forEach((element, index) => {
            response.data.teams.teamsByDay.days[index] = timeZoneDate(element + ' 23:00:00')
          })
          this.teamsByDayChart.chartOptions.xaxis.categories = response.data.teams.teamsByDay.days
          this.teamsByDayChart.ready = true

          this.teamsByWeekChart.series = []
          this.teamsByWeekChart.chartOptions.xaxis.categories = []
          response.data.teams.teamsByWeek.data.forEach((element, index) => {
            this.teamsByWeekChart.series.push({
              name: !empty(element.name) ? element.name : 'S/N',
              data: element.data
            })
          })
          response.data.teams.teamsByWeek.days.forEach((element, index) => {
            response.data.teams.teamsByWeek.days[index] = element.toString()
          })
          this.teamsByWeekChart.chartOptions.xaxis.categories = response.data.teams.teamsByWeek.days
          this.teamsByWeekChart.ready = true
        }

        if (!empty(response.data.metersInfo)) {
          this.totalTransmitting = response.data.metersInfo.meters[0].total
          this.responseMetering = response.data.metersInfo.repeated
        }
        this.createCsv()
      })
    },
    createCsv () {
      const csv = []
      const headers = []
      headers.push('Agencia')
      headers.push('Total Gabinetes')
      csv.push(headers.join(','))
      this.responseData.map(item => {
        const row = []
        row.push(item.name)
        row.push(item.total)
        csv.push(row.join(','))
        return item
      })
      const file = csv.join('\n')
      const csvFile = new Blob([file], { type: 'text/csv' })
      this.urlDownload = window.URL.createObjectURL(csvFile)
    }
  },
  created () {
    this.getDashboard()
  }
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  @import '@core/scss/vue/libs/chart-apex.scss';
</style>
